























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_DEPARTMENTS, GET_DEPARTMENTS } from '../store';
import { ConservatorshipDetails } from '../../conservatorship/types';
import DepartmentCard from './DepartmentCard.vue';
import { Department } from '../types';
import { ApiResponse } from '@/components/types';
import ContactHeaderCard from './ContactHeaderCard.vue';

const AddDepartmentDialog = () => import('./AddDepartmentDialog.vue');
const UpdateDepartmentDialog = () => import('./UpdateDepartmentDialog.vue');

const Conservatorship = namespace('conservatorshipToContact');

@Component({
  components: { UpdateDepartmentDialog, AddDepartmentDialog, ContactHeaderCard, DepartmentCard }
})
export default class DepartmentPlugin extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;

  @Conservatorship.Getter(GET_DEPARTMENTS) getDepartmentsByConservatorship!: (conservatorship_id: string) => Department[];
  @Conservatorship.Action(FETCH_DEPARTMENTS) fetchDepartments!: (conservatorship_id: string) => Promise<ApiResponse>;

  loading: boolean = false;
  loaded: boolean = false;
  updateDepartment: null | Department = null;

  get departments() {
    return this.getDepartmentsByConservatorship(this.conservatorship.id);
  }

  @Watch('conservatorship', { immediate: true })
  async onConservatorshipChange(conservatorship: ConservatorshipDetails) {
    this.loading = true;

    await this.fetchDepartments(conservatorship.id);

    this.loading = false;
    this.loaded = true;
  }
}
