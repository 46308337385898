























































import { Component, Prop, Vue } from 'vue-property-decorator';
import PhoneRow from '@/components/PhoneRow.vue';
import CardRow from '@/components/CardRow.vue';
import { Department } from '../types';

const CreateLetterButton = () => import('@/modules/correspondence/components/CreateLetterButton.vue');
const ContactPersonsDialog = () => import('@/modules/conservatorshipToContact/components/ContactPersonsDialog.vue');

@Component({
  components: {
    CreateLetterButton,
    ContactPersonsDialog,
    PhoneRow,
    CardRow
  }
})
export default class DepartmentCard extends Vue {
  @Prop({ type: Object, required: true }) department!: Department;
}
